@import 'colors/colors';

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background-color: $gray;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
}

.ant-tooltip-inner {
  color: #000 !important;
  background: #fff !important;
}

.ant-tooltip-arrow {
  display: none !important;
}

.ant-btn {
  & > span {
    font-weight: 500 !important;
  }

  &.ant-btn-link,
  &.ant-btn-text > div {
    border-radius: 0 !important;
  }

  &.ant-btn-link {
    padding: 0 !important;
  }

  &.ant-btn-primary {
    box-shadow: none !important;
    background-color: $light_blue;
  }

  &.ant-btn-default {
    border-color: $light_blue;
    color: $light_blue;
  }
}

.ant-table {
  $table: &;

  #{$table}-tbody {
    #{$table}-cell {
      line-height: 18px !important;

      & > div,
      & > span,
      & > p {
        line-height: 18px !important;
      }

      .ant-typography {
        line-height: 18px !important;
      }
    }
  }

  &-cell {
    &::before {
      display: none;
    }
  }

  &-column-sorters {
    gap: 2px;
    color: $blue;
  }
}

.ant-typography {
  margin-bottom: 0 !important;

  &-copy {
    height: 16px !important;
    margin-left: 6px !important;
    display: inline !important;
    position: relative;

    svg,
    span {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.ant-modal {
  &-content {
    border-radius: 16px !important;
  }

  &-header {
    border-top-right-radius: 16px !important;
    border-top-left-radius: 16px !important;
    margin-bottom: 0 !important;
  }
}

// Сделано для того, чтобы выбранный элемент в TreeSelect в случае, если не помещается в контейнер >
// выбранных элементов, не сжимался до +X..., а отображал текст выбранного элемента.
.ant-select {
  &-selection-overflow {
    flex-wrap: nowrap !important;
    gap: 5px;

    &-item {
      flex-shrink: 1 !important;
      min-width: 50px;

      &-rest {
        flex-shrink: 0 !important;
      }
    }
  }
}

.ant-picker {
  height: 40px !important;
}