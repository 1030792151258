@import 'colors/colors';
@import "./mixins";

.global_GPB {
  * {
    font-family: var(--font-inter), sans-serif !important;
  }

  input::placeholder,
  textarea::placeholder {
    font-family: var(--font-inter), sans-serif;
  }
}

.global_PIRSON {
  * {
    font-family: var(--font-inter), sans-serif !important;
  }

  input::placeholder,
  textarea::placeholder {
    font-family: var(--font-inter), sans-serif;
  }
}

.hint-icon {
  color: $light_blue;
  flex-shrink: 0;
  cursor: help;
}

.v-icon {
  color: $light_blue;
  transition: transform 0.25s linear;
}

.basic-icon {
  flex-shrink: 0;
}

.input-line {
  padding: 0 !important;
  font-size: 18px !important;
  border-radius: 0 !important;
  color: $black !important;
  border-bottom: 1px solid $input_border_gray !important;

  &::placeholder {
    color: $light_gray !important;
    opacity: 0.5 !important;
  }

  &:focus,
  &:hover {
    border-bottom-color: $input_focus_border_blue !important;
  }
}

.unauthorized-modal {
  .ant-modal-confirm-title {
    font-weight: 500 !important;
  }

  .ant-modal-confirm-btns {
    margin-top: 30px !important;
  }
}

.table-with-sticky-footer {
  position: relative !important;

  .ant-table-footer {
    @include sticky-element();
  }
}

.button-with-icon {
  display: flex !important;
  align-items: center;
}

.button-dashed {
  &:not(:disabled) {
    border-bottom: 1px dashed $blue;

    &:hover {
      border-bottom-color: lighten($blue, 25%);
    }
  }
}
